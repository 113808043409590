import routesObj from '@src/config/routes';

const isAToken = ([token]) => token === ':';

const throwSegmentsError = (url, segments, args) => {
  throw new Error(`The url generator for ${url} was expecting ${segments} arguments,
    but received ${args}.`);
};

const applyUrlSegments = (url, segments) => {
  return (...args) => {
    if (args.length !== segments) {
      return throwSegmentsError(url, segments, args.length);
    }
    return url
      .split('/')
      .map((segment) => {
        if (isAToken(segment)) {
          return args.shift();
        }
        return segment;
      }, args)
      .join('/');
  };
};

const getUrlGenerator = (url) => {
  const segments = url.split('/').filter(isAToken).length;
  if (segments) {
    return applyUrlSegments(url, segments);
  }
  return () => url;
};

export const getFullRoutes = (routes) => {
  if (typeof routes === 'string') {
    return {
      url: getUrlGenerator(routes),
      expr: routes,
    };
  }
  return Object.keys(routes).reduce((total, key) => {
    // eslint-disable-next-line no-param-reassign
    routes[key] = getFullRoutes(routes[key]);
    return routes;
  }, routes);
};

export default getFullRoutes(routesObj);
