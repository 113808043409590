/* eslint-disable no-case-declarations */
import { union } from 'lodash';
import { ProjectStatus } from '@src/constants/defaultValues';
import {
  GET_PROJECT_DETAIL,
  GET_PROJECT_DETAIL_SUCCESS,
  GET_PROJECT_DETAIL_ERROR,
  ADD_PROJECT_PRESUPOSITION,
  ADD_PROJECT_PRESUPOSITION_ERROR,
  DELETE_DETAIL_PROJECT,
  DELETE_DETAIL_PROJECT_SUCCESS,
  DELETE_DETAIL_PROJECT_ERROR,
  SET_INIT_DIRECT_COST,
  SET_INIT_DIRECT_COST_SUCCESS,
  SET_INIT_DIRECT_COST_ERROR,
  UPDATE_INIT_DIRECT_COST,
  UPDATE_INIT_DIRECT_COST_SUCCESS,
  UPDATE_INIT_DIRECT_COST_ERROR,
  SET_SUBDIRECT_COST,
  SET_SUBDIRECT_COST_SUCCESS,
  SET_SUBDIRECT_COST_ERROR,
  UPDATE_SUBDIRECT_COST,
  UPDATE_SUBDIRECT_COST_SUCCESS,
  UPDATE_SUBDIRECT_COST_ERROR,
  DELETE_SUBDIRECT_COST,
  DELETE_SUBDIRECT_COST_SUCCESS,
  DELETE_SUBDIRECT_COST_ERROR,
  SET_INIT_INDIRECT_COST,
  SET_INIT_INDIRECT_COST_SUCCESS,
  SET_INIT_INDIRECT_COST_ERROR,
  UPDATE_INIT_INDIRECT_COST,
  UPDATE_INIT_INDIRECT_COST_SUCCESS,
  UPDATE_INIT_INDIRECT_COST_ERROR,
  DELETE_INIT_INDIRECT_COST,
  DELETE_INIT_INDIRECT_COST_SUCCESS,
  DELETE_INIT_INDIRECT_COST_ERROR,
  GET_PRODUCTIONS,
  GET_PRODUCTIONS_SUCCESS,
  GET_PRODUCTIONS_ERROR,
  SET_PRODUCTION,
  SET_PRODUCTION_SUCCESS,
  SET_PRODUCTION_ERROR,
  CLOSE_PRODUCTION_MONTH,
  CLOSE_PRODUCTION_MONTH_SUCCESS,
  CLOSE_PRODUCTION_MONTH_ERROR,
  GET_PROJECT_TIMELINES,
  GET_PROJECT_TIMELINES_SUCCESS,
  GET_PROJECT_TIMELINES_ERROR,
  GET_INDIRECT_COSTS,
  GET_INDIRECT_COSTS_SUCCESS,
  GET_INDIRECT_COSTS_ERROR,
  ADD_INDIRECT_COST,
  ADD_INDIRECT_COST_SUCCESS,
  ADD_INDIRECT_COST_ERROR,
  UPDATE_INDIRECT_COST,
  UPDATE_INDIRECT_COST_SUCCESS,
  UPDATE_INDIRECT_COST_ERROR,
  DEL_INDIRECT_COST,
  DEL_INDIRECT_COST_SUCCESS,
  DEL_INDIRECT_COST_ERROR,
  GET_DELIVERYNOTES,
  GET_DELIVERYNOTES_SUCCESS,
  GET_DELIVERYNOTES_ERROR,
  ADD_DELIVERYNOTE,
  ADD_DELIVERYNOTE_SUCCESS,
  ADD_DELIVERYNOTE_ERROR,
  UPDATE_DELIVERYNOTE,
  UPDATE_DELIVERYNOTE_SUCCESS,
  UPDATE_DELIVERYNOTE_ERROR,
  DEL_DELIVERYNOTE,
  DEL_DELIVERYNOTE_SUCCESS,
  DEL_DELIVERYNOTE_ERROR,
  GET_SUBCONTRACTORS,
  GET_SUBCONTRACTORS_SUCCESS,
  GET_SUBCONTRACTORS_ERROR,
  ADD_SUBCONTRACTOR,
  ADD_SUBCONTRACTOR_SUCCESS,
  ADD_SUBCONTRACTOR_ERROR,
  UPDATE_SUBCONTRACTOR,
  UPDATE_SUBCONTRACTOR_SUCCESS,
  UPDATE_SUBCONTRACTOR_ERROR,
  DEL_SUBCONTRACTOR,
  DEL_SUBCONTRACTOR_SUCCESS,
  DEL_SUBCONTRACTOR_ERROR,
  UPDATE_PROJECT_GLOBALS,
  CLOSE_INITIAL_STUDY,
  CLOSE_INITIAL_STUDY_SUCCESS,
  CLOSE_INITIAL_STUDY_ERROR,
  APPROVE_INITIAL_STUDY,
  APPROVE_INITIAL_STUDY_SUCCESS,
  APPROVE_INITIAL_STUDY_ERROR,
  REVISE_INITIAL_STUDY,
  REVISE_INITIAL_STUDY_SUCCESS,
  REVISE_INITIAL_STUDY_ERROR,
  CLOSE_REVISED_INITIAL_STUDY,
  CLOSE_REVISED_INITIAL_STUDY_SUCCESS,
  CLOSE_REVISED_INITIAL_STUDY_ERROR,
  START_CONSTRUCTION,
  START_CONSTRUCTION_SUCCESS,
  START_CONSTRUCTION_ERROR,
  CANCEL_CONSTRUCTION,
  CANCEL_CONSTRUCTION_SUCCESS,
  CANCEL_CONSTRUCTION_ERROR,
  FINISH_CONSTRUCTION,
  FINISH_CONSTRUCTION_SUCCESS,
  FINISH_CONSTRUCTION_ERROR,
} from '../actions';

const INIT_STATE = {
  data: {},
  productionData: {},
  timelines: [],
  indirectCostsData: {},
  deliveryNotesData: {},
  subcontractorsData: {},
  loading: false,
  error: '',
};

let newPresupuesto;
let newInitIndirectos;
let newProducciones;
let newIndirectos;
let newAlbaranes;
let newSubcontratistas;

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECT_DETAIL:
      return { ...state, data: {}, loading: true, error: '' };
    case GET_PROJECT_DETAIL_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: '' };
    case GET_PROJECT_DETAIL_ERROR:
      return {
        ...state,
        data: {},
        loading: false,
        error: action.payload.message,
      };

    case ADD_PROJECT_PRESUPOSITION:
      return { ...state, loading: true, error: '' };
    case ADD_PROJECT_PRESUPOSITION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case DELETE_DETAIL_PROJECT:
      return { ...state, loading: true, error: '' };
    case DELETE_DETAIL_PROJECT_SUCCESS:
      return {
        ...INIT_STATE,
      };
    case DELETE_DETAIL_PROJECT_ERROR:
      return {
        ...state,
        data: {},
        loading: false,
        error: action.payload.message,
      };

    case SET_INIT_DIRECT_COST:
      return {
        ...state,
        error: '',
      };
    case SET_INIT_DIRECT_COST_SUCCESS:
      newPresupuesto = state.data.presupuesto.map((item) => {
        if (item.numero === action.payload.numero) {
          const newCosteDirecto = item.costedirecto.map((element) => {
            if (element.numero === action.payload.numero) {
              return action.payload;
            }
            return element;
          });
          return {
            ...item,
            costedirecto: newCosteDirecto,
          };
        }
        return item;
      });
      return {
        ...state,
        data: {
          ...state.data,
          presupuesto: newPresupuesto,
        },
        error: '',
      };
    case SET_INIT_DIRECT_COST_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case SET_SUBDIRECT_COST:
      return {
        ...state,
        error: '',
      };
    case SET_SUBDIRECT_COST_SUCCESS:
      newPresupuesto = state.data.presupuesto.map((item) => {
        if (item.id_coste_directo === action.payload.id_coste_directo) {
          return {
            ...item,
            costedirecto: union(item.costedirecto, [action.payload]),
          };
        }
        return item;
      });
      return {
        ...state,
        data: {
          ...state.data,
          presupuesto: newPresupuesto,
        },
        error: '',
      };
    case SET_SUBDIRECT_COST_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case UPDATE_INIT_DIRECT_COST:
      return {
        ...state,
        error: '',
      };
    case UPDATE_INIT_DIRECT_COST_SUCCESS:
      newPresupuesto = state.data.presupuesto.map((item) => {
        if (item.id_coste_directo === action.payload.id_coste_directo) {
          return {
            ...item,
            costedirecto: item.costedirecto.map((element) => {
              if (
                element.id_coste_directo_estudio ===
                action.payload.id_coste_directo_estudio
              ) {
                return action.payload;
              }
              return element;
            }),
          };
        }
        return item;
      });
      return {
        ...state,
        data: {
          ...state.data,
          presupuesto: newPresupuesto,
        },
        error: '',
      };
    case UPDATE_INIT_DIRECT_COST_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case UPDATE_SUBDIRECT_COST:
      return {
        ...state,
        error: '',
      };
    case UPDATE_SUBDIRECT_COST_SUCCESS:
      newPresupuesto = state.data.presupuesto.map((item) => {
        if (item.id_coste_directo === action.payload.id_coste_directo) {
          return {
            ...item,
            costedirecto: item.costedirecto.map((element) => {
              if (
                element.id_coste_directo_estudio ===
                action.payload.id_coste_directo_estudio
              ) {
                return action.payload;
              }
              return element;
            }),
          };
        }
        return item;
      });
      return {
        ...state,
        data: {
          ...state.data,
          presupuesto: newPresupuesto,
        },
        error: '',
      };
    case UPDATE_SUBDIRECT_COST_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case DELETE_SUBDIRECT_COST:
      return {
        ...state,
        error: '',
      };
    case DELETE_SUBDIRECT_COST_SUCCESS:
      newPresupuesto = state.data.presupuesto.map((item) => {
        return {
          ...item,
          costedirecto: item.costedirecto.filter(
            (element) => element.id_coste_directo_estudio !== action.payload
          ),
        };
      });
      return {
        ...state,
        data: {
          ...state.data,
          presupuesto: newPresupuesto,
        },
        error: '',
      };
    case DELETE_SUBDIRECT_COST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case SET_INIT_INDIRECT_COST:
      return {
        ...state,
        error: '',
      };
    case SET_INIT_INDIRECT_COST_SUCCESS:
      newInitIndirectos = union(state.data.indirectos, [action.payload]);
      return {
        ...state,
        data: {
          ...state.data,
          indirectos: newInitIndirectos,
        },
        error: '',
      };
    case SET_INIT_INDIRECT_COST_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case UPDATE_INIT_INDIRECT_COST:
      return {
        ...state,
        error: '',
      };
    case UPDATE_INIT_INDIRECT_COST_SUCCESS:
      newInitIndirectos = state.data.indirectos.map((item) => {
        if (
          item.id_coste_indirecto_estudio ===
          action.payload.id_coste_indirecto_estudio
        ) {
          return action.payload;
        }
        return item;
      });
      return {
        ...state,
        data: {
          ...state.data,
          indirectos: newInitIndirectos,
        },
        error: '',
      };
    case UPDATE_INIT_INDIRECT_COST_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case DELETE_INIT_INDIRECT_COST:
      return {
        ...state,
        error: '',
      };
    case DELETE_INIT_INDIRECT_COST_SUCCESS:
      newInitIndirectos = state.data.indirectos.filter(
        (item) => item.id_coste_indirecto_estudio !== action.payload
      );
      return {
        ...state,
        data: {
          ...state.data,
          indirectos: newInitIndirectos,
        },
        error: '',
      };
    case DELETE_INIT_INDIRECT_COST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case GET_PRODUCTIONS:
      return {
        ...state,
        error: '',
      };
    case GET_PRODUCTIONS_SUCCESS:
      return {
        ...state,
        productionData: action.payload,
      };
    case GET_PRODUCTIONS_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case SET_PRODUCTION:
      return {
        ...state,
        error: '',
      };
    case SET_PRODUCTION_SUCCESS:
      newProducciones = state.productionData.produccionesProyecto.map(
        (item) => {
          if (item.id_coste_directo === action.payload.id_coste_directo) {
            return {
              ...item,
              POM: {
                ...item.POM,
                cantidad: action.payload.POM.cantidad,
                importe: action.payload.POM.importe,
              },
              PM: {
                ...item.PM,
                cantidad: action.payload.PM.cantidad,
                importe: action.payload.PM.importe,
              },
            };
          }
          return item;
        }
      );

      return {
        ...state,
        productionData: {
          ...state.productionData,
          resultadoMes: action.payload.resultadoMes,
          produccionesProyecto: newProducciones,
        },
      };

    case SET_PRODUCTION_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case CLOSE_PRODUCTION_MONTH:
      return { ...state, loading: true, error: '' };

    case CLOSE_PRODUCTION_MONTH_SUCCESS:
      return { ...state, loading: false, error: '' };

    case CLOSE_PRODUCTION_MONTH_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    case GET_PROJECT_TIMELINES:
      return { ...state, error: '' };

    case GET_PROJECT_TIMELINES_SUCCESS:
      return { ...state, timelines: action.payload };

    case GET_PROJECT_TIMELINES_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case GET_INDIRECT_COSTS:
      return { ...state, error: '' };

    case GET_INDIRECT_COSTS_SUCCESS:
      return { ...state, indirectCostsData: action.payload };

    case GET_INDIRECT_COSTS_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case ADD_INDIRECT_COST:
      return { ...state, error: '' };

    case ADD_INDIRECT_COST_SUCCESS:
      return {
        ...state,
        indirectCostsData: {
          ...state.indirectCostsData,
          indirectosMes: [
            ...state.indirectCostsData.indirectosMes,
            action.payload.newCost.registro,
          ],
          totalAlmacenMes: action.payload.newCost.totalAlmacenMes,
          totalIndirectosMes: action.payload.newCost.totalIndirectosMes,
        },
      };

    case ADD_INDIRECT_COST_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case UPDATE_INDIRECT_COST:
      return { ...state, error: '' };

    case UPDATE_INDIRECT_COST_SUCCESS:
      if (!action.payload.isAllMode) {
        const newProjIndirectos = state.indirectCostsData.indirectosMes.map(
          (item) => {
            if (
              item.id_coste_indirecto !==
              action.payload.updatedCost.registro.id_coste_indirecto
            ) {
              return item;
            }
            return { ...action.payload.updatedCost.registro };
          }
        );
        return {
          ...state,
          indirectCostsData: {
            ...state.indirectCostsData,
            indirectosMes: newProjIndirectos,
            totalAlmacenMes: action.payload.updatedCost.totalAlmacenMes,
            totalIndirectosMes: action.payload.updatedCost.totalIndirectosMes,
          },
        };
      }
      newIndirectos = state.indirectCostsData.indirectosProyecto.map((item) => {
        return {
          ...item,
          indirectos: item.indirectos.map((indirecto) => {
            if (
              indirecto.id_coste_indirecto !==
              action.payload.updatedCost.registro.id_coste_indirecto
            ) {
              return indirecto;
            }
            return { ...action.payload.updatedCost.registro };
          }),
        };
      });
      return {
        ...state,
        indirectCostsData: {
          ...state.indirectCostsData,
          indirectosProyecto: newIndirectos,
          totalAlmacenProyecto: action.payload.updatedCost.totalAlmacenProyecto,
          totalIndirectosProyecto:
            action.payload.updatedCost.totalIndirectosProyecto,
        },
      };

    case UPDATE_INDIRECT_COST_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case DEL_INDIRECT_COST:
      return { ...state, error: '' };

    case DEL_INDIRECT_COST_SUCCESS:
      if (!action.payload.isAllMode) {
        newIndirectos = state.indirectCostsData.indirectosMes.filter(
          (item) => item.id_coste_indirecto !== action.payload.idIndirectCost
        );
        return {
          ...state,
          indirectCostsData: {
            ...state.indirectCostsData,
            indirectosMes: newIndirectos,
            totalAlmacenMes: action.payload.data.totalAlmacenMes,
            totalIndirectosMes: action.payload.data.totalIndirectosMes,
          },
        };
      }
      newIndirectos = state.indirectCostsData.indirectosProyecto.map((item) => {
        return {
          ...item,
          indirectos: item.indirectos.filter(
            (indirecto) =>
              indirecto.id_coste_indirecto !== action.payload.idIndirectCost
          ),
        };
      });
      return {
        ...state,
        indirectCostsData: {
          ...state.indirectCostsData,
          indirectosProyecto: newIndirectos,
          totalAlmacenProyecto: action.payload.data.totalAlmacenProyecto,
          totalIndirectosProyecto: action.payload.data.totalIndirectosProyecto,
        },
      };

    case DEL_INDIRECT_COST_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case GET_DELIVERYNOTES:
      return { ...state, error: '' };

    case GET_DELIVERYNOTES_SUCCESS:
      return { ...state, deliveryNotesData: action.payload };

    case GET_DELIVERYNOTES_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case ADD_DELIVERYNOTE:
      return { ...state, error: '' };

    case ADD_DELIVERYNOTE_SUCCESS:
      return {
        ...state,
        deliveryNotesData: {
          ...state.deliveryNotesData,
          albaranesMes: [
            ...state.deliveryNotesData.albaranesMes,
            action.payload.newDelivery.registro,
          ],
          totalAlbaranesMes: action.payload.newDelivery.totalAlbaranesMes,
          totalAlmacenMes: action.payload.newDelivery.totalAlmacenMes,
        },
      };

    case ADD_DELIVERYNOTE_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case UPDATE_DELIVERYNOTE:
      return { ...state, error: '' };

    case UPDATE_DELIVERYNOTE_SUCCESS:
      if (!action.payload.isAllMode) {
        const newProjAlbaranes = state.deliveryNotesData.albaranesMes.map(
          (item) => {
            if (
              item.id_albaran !==
              action.payload.updatedDelivery.registro.id_albaran
            ) {
              return item;
            }
            return { ...action.payload.updatedDelivery.registro };
          }
        );
        return {
          ...state,
          deliveryNotesData: {
            ...state.deliveryNotesData,
            albaranesMes: newProjAlbaranes,
            totalAlmacenMes: action.payload.updatedDelivery.totalAlmacenMes,
            totalAlbaranesMes: action.payload.updatedDelivery.totalAlbaranesMes,
          },
        };
      }
      newAlbaranes = state.deliveryNotesData.albaranesProyecto.map((item) => {
        return {
          ...item,
          albaranes: item.albaranes.map((albaran) => {
            if (
              albaran.id_albaran !==
              action.payload.updatedDelivery.registro.id_albaran
            ) {
              return albaran;
            }
            return { ...action.payload.updatedDelivery.registro };
          }),
        };
      });
      return {
        ...state,
        deliveryNotesData: {
          ...state.deliveryNotesData,
          albaranesProyecto: newAlbaranes,
          totalAlmacenProyecto:
            action.payload.updatedDelivery.totalAlmacenProyecto,
          totalAlbaranesProyecto:
            action.payload.updatedDelivery.totalAlbaranesProyecto,
        },
      };

    case UPDATE_DELIVERYNOTE_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case DEL_DELIVERYNOTE:
      return { ...state, error: '' };

    case DEL_DELIVERYNOTE_SUCCESS:
      if (!action.payload.isAllMode) {
        newAlbaranes = state.deliveryNotesData.albaranesMes.filter(
          (item) => item.id_albaran !== action.payload.idDelivery
        );
        return {
          ...state,
          deliveryNotesData: {
            ...state.deliveryNotesData,
            albaranesMes: newAlbaranes,
            totalAlmacenMes: action.payload.data.totalAlmacenMes,
            totalAlbaranesMes: action.payload.data.totalAlbaranesMes,
          },
        };
      }
      newAlbaranes = state.deliveryNotesData.albaranesProyecto.map((item) => {
        return {
          ...item,
          albaranes: item.albaranes.filter(
            (albaran) => albaran.id_albaran !== action.payload.idDelivery
          ),
        };
      });
      return {
        ...state,
        deliveryNotesData: {
          ...state.deliveryNotesData,
          albaranesProyecto: newAlbaranes,
          totalAlmacenProyecto: action.payload.data.totalAlmacenProyecto,
          totalAlbaranesMes: action.payload.data.totalAlbaranesMes,
        },
      };

    case DEL_DELIVERYNOTE_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case GET_SUBCONTRACTORS:
      return { ...state, error: '' };

    case GET_SUBCONTRACTORS_SUCCESS:
      return { ...state, subcontractorsData: action.payload };

    case GET_SUBCONTRACTORS_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case ADD_SUBCONTRACTOR:
      return { ...state, error: '' };

    case ADD_SUBCONTRACTOR_SUCCESS:
      return {
        ...state,
        subcontractorsData: {
          ...state.subcontractorsData,
          subcontratistasMes: [
            ...state.subcontractorsData.subcontratistasMes,
            action.payload.newSubcontractor.registro,
          ],
          totalSubcontratistasMes:
            action.payload.newSubcontractor.totalSubcontratistasMes,
        },
      };

    case ADD_SUBCONTRACTOR_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case UPDATE_SUBCONTRACTOR:
      return { ...state, error: '' };

    case UPDATE_SUBCONTRACTOR_SUCCESS:
      if (!action.payload.isAllMode) {
        const newProjSubcontratistas = state.subcontractorsData.subcontratistasMes.map(
          (item) => {
            if (
              item.id_subcontratista !==
              action.payload.updatedSubcontractor.registro.id_subcontratista
            ) {
              return item;
            }
            return { ...action.payload.updatedSubcontractor.registro };
          }
        );
        return {
          ...state,
          subcontractorsData: {
            ...state.subcontractorsData,
            subcontratistasMes: newProjSubcontratistas,
            totalSubcontratistasMes:
              action.payload.updatedSubcontractor.totalSubcontratistasMes,
          },
        };
      }
      newSubcontratistas = state.subcontractorsData.subcontratistasProyecto.map(
        (item) => {
          return {
            ...item,
            subcontratistas: item.subcontratistas.map((subcontratista) => {
              if (
                subcontratista.id_subcontratista !==
                action.payload.updatedSubcontractor.registro.id_subcontratista
              ) {
                return subcontratista;
              }
              return { ...action.payload.updatedSubcontractor.registro };
            }),
          };
        }
      );
      return {
        ...state,
        subcontractorsData: {
          ...state.subcontractorsData,
          subcontratistasProyecto: newSubcontratistas,
          totalSubcontratistasProyecto:
            action.payload.updatedSubcontractor.totalSubcontratistasProyecto,
        },
      };

    case UPDATE_SUBCONTRACTOR_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case DEL_SUBCONTRACTOR:
      return { ...state, error: '' };

    case DEL_SUBCONTRACTOR_SUCCESS:
      if (!action.payload.isAllMode) {
        newSubcontratistas = state.subcontractorsData.subcontratistasMes.filter(
          (item) => item.id_subcontratista !== action.payload.idSubcontractor
        );
        return {
          ...state,
          subcontractorsData: {
            ...state.subcontractorsData,
            subcontratistasMes: newSubcontratistas,
            totalSubcontratistasMes:
              action.payload.data.totalSubcontratistasMes,
          },
        };
      }
      newSubcontratistas = state.subcontractorsData.subcontratistasProyecto.map(
        (item) => {
          return {
            ...item,
            subcontratistas: item.subcontratistas.filter(
              (subcontratista) =>
                subcontratista.id_subcontratista !==
                action.payload.idSubcontractor
            ),
          };
        }
      );
      return {
        ...state,
        subcontractorsData: {
          ...state.subcontractorsData,
          subcontratistasProyecto: newSubcontratistas,
          totalSubcontratistasProyecto:
            action.payload.data.totalSubcontratistasProyecto,
        },
      };

    case DEL_SUBCONTRACTOR_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };

    case UPDATE_PROJECT_GLOBALS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    case CLOSE_INITIAL_STUDY:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case CLOSE_INITIAL_STUDY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          estadoProyecto: {
            ...state.data.estadoProyecto,
            id_estado_proyecto: ProjectStatus.PendienteAprobacion,
          },
        },
        loading: false,
        error: '',
      };
    case CLOSE_INITIAL_STUDY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case APPROVE_INITIAL_STUDY:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case APPROVE_INITIAL_STUDY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          estadoProyecto: {
            ...state.data.estadoProyecto,
            id_estado_proyecto: ProjectStatus.Aprobado,
          },
        },
        loading: false,
        error: '',
      };
    case APPROVE_INITIAL_STUDY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case REVISE_INITIAL_STUDY:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case REVISE_INITIAL_STUDY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          estadoProyecto: {
            ...state.data.estadoProyecto,
            id_estado_proyecto: ProjectStatus.EnRevision,
          },
        },
        loading: false,
        error: '',
      };
    case REVISE_INITIAL_STUDY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case CLOSE_REVISED_INITIAL_STUDY:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case CLOSE_REVISED_INITIAL_STUDY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          estadoProyecto: {
            ...state.data.estadoProyecto,
            id_estado_proyecto: ProjectStatus.PendienteAprobacion,
          },
        },
        loading: false,
        error: '',
      };
    case CLOSE_REVISED_INITIAL_STUDY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case START_CONSTRUCTION:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case START_CONSTRUCTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          estadoProyecto: {
            ...state.data.estadoProyecto,
            id_estado_proyecto: ProjectStatus.EnEjecucion,
          },
        },
        loading: false,
        error: '',
      };
    case START_CONSTRUCTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case CANCEL_CONSTRUCTION:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case CANCEL_CONSTRUCTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          estadoProyecto: {
            ...state.data.estadoProyecto,
            id_estado_proyecto: ProjectStatus.Cancelado,
          },
        },
        loading: false,
        error: '',
      };
    case CANCEL_CONSTRUCTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case FINISH_CONSTRUCTION:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case FINISH_CONSTRUCTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          estadoProyecto: {
            ...state.data.estadoProyecto,
            id_estado_proyecto: ProjectStatus.Finalizado,
          },
        },
        loading: false,
        error: '',
      };
    case FINISH_CONSTRUCTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    default:
      return { ...state };
  }
};
