import { combineReducers } from 'redux';
import menu from './menu/reducer';
import settings from './settings/reducer';
import actionConfirmer from './actionConfirmer/reducer';
import authUser from './auth/reducer';
import projects from './projects/reducer';
import projectDetail from './projectDetail/reducer';

const reducers = combineReducers({
  menu,
  settings,
  actionConfirmer,
  authUser,
  projects,
  projectDetail,
});

export default reducers;
