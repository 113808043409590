// eslint-disable-next-line import/no-cycle
import { SET_ACTION_CONFIRM, CLEAR_ACTION_CONFIRM } from '../actions';

export const setActionToConfirm = (payload) => ({
  type: SET_ACTION_CONFIRM,
  payload,
});

export const clearActionToConfirm = () => ({
  type: CLEAR_ACTION_CONFIRM,
});
