/* eslint-disable import/no-cycle */
import * as menu from './menu/actions';
import * as settings from './settings/actions';
import * as actionConfirmer from './actionConfirmer/actions';
import * as auth from './auth/actions';
import * as projects from './projects/actions';
import * as projectDetail from './projectDetail/actions';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* ACTION CONFIRMER */
export const SET_ACTION_CONFIRM = 'SET_ACTION_CONFIRM';
export const CLEAR_ACTION_CONFIRM = 'CLEAR_ACTION_CONFIRM';

/* AUTH */
export const LOAD_USER = 'LOAD_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const CHECK_AUTH = 'CHECK_AUTH';
export const CHECK_AUTH_SUCCESS = 'CHECK_AUTH_SUCCESS';
export const CHECK_AUTH_ERROR = 'CHECK_AUTH_ERROR';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

/* PROJECTS */
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_ERROR = 'GET_PROJECTS_ERROR';

/* PROJECT DETAIL */
export const GET_PROJECT_DETAIL = 'GET_PROJECT_DETAIL';
export const GET_PROJECT_DETAIL_SUCCESS = 'GET_PROJECT_DETAIL_SUCCESS';
export const GET_PROJECT_DETAIL_ERROR = 'GET_PROJECT_DETAIL_ERROR';
export const ADD_PROJECT_PRESUPOSITION = 'ADD_PROJECT_PRESUPOSITION';
export const ADD_PROJECT_PRESUPOSITION_ERROR =
  'ADD_PROJECT_PRESUPOSITION_ERROR';
export const ADD_PROJECT_EXTENSION = 'ADD_PROJECT_EXTENSION';
export const ADD_PROJECT_EXTENSION_ERROR =
    'ADD_PROJECT_EXTENSION_ERROR';
export const DELETE_DETAIL_PROJECT = 'DELETE_DETAIL_PROJECT';
export const DELETE_DETAIL_PROJECT_SUCCESS = 'DELETE_DETAIL_PROJECT_SUCCESS';
export const DELETE_DETAIL_PROJECT_ERROR = 'DELETE_DETAIL_PROJECT_ERROR';
export const SET_INIT_DIRECT_COST = 'SET_INIT_DIRECT_COST';
export const SET_INIT_DIRECT_COST_SUCCESS = 'SET_INIT_DIRECT_COST_SUCCESS';
export const SET_INIT_DIRECT_COST_ERROR = 'SET_INIT_DIRECT_COST_ERROR';
export const UPDATE_INIT_DIRECT_COST = 'UPDATE_INIT_DIRECT_COST';
export const UPDATE_INIT_DIRECT_COST_SUCCESS =
  'UPDATE_INIT_DIRECT_COST_SUCCESS';
export const UPDATE_INIT_DIRECT_COST_ERROR = 'UPDATE_INIT_DIRECT_COST_ERROR';
export const SET_SUBDIRECT_COST = 'SET_SUBDIRECT_COST';
export const SET_SUBDIRECT_COST_SUCCESS = 'SET_SUBDIRECT_COST_SUCCESS';
export const SET_SUBDIRECT_COST_ERROR = 'SET_SUBDIRECT_COST_ERROR';
export const UPDATE_SUBDIRECT_COST = 'UPDATE_SUBDIRECT_COST';
export const UPDATE_SUBDIRECT_COST_SUCCESS = 'UPDATE_SUBDIRECT_COST_SUCCESS';
export const UPDATE_SUBDIRECT_COST_ERROR = 'UPDATE_SUBDIRECT_COST_ERROR';
export const DELETE_SUBDIRECT_COST = 'DELETE_SUBDIRECT_COST';
export const DELETE_SUBDIRECT_COST_SUCCESS = 'DELETE_SUBDIRECT_COST_SUCCESS';
export const DELETE_SUBDIRECT_COST_ERROR = 'DELETE_SUBDIRECT_COST_ERROR';
export const SET_INIT_INDIRECT_COST = 'SET_INIT_INDIRECT_COST';
export const SET_INIT_INDIRECT_COST_SUCCESS = 'SET_INIT_INDIRECT_COST_SUCCESS';
export const SET_INIT_INDIRECT_COST_ERROR = 'SET_INIT_INDIRECT_COST_ERROR';
export const UPDATE_INIT_INDIRECT_COST = 'UPDATE_INIT_INDIRECT_COST';
export const UPDATE_INIT_INDIRECT_COST_SUCCESS =
  'UPDATE_INIT_INDIRECT_COST_SUCCESS';
export const UPDATE_INIT_INDIRECT_COST_ERROR =
  'UPDATE_INIT_INDIRECT_COST_ERROR';
export const DELETE_INIT_INDIRECT_COST = 'DELETE_INIT_INDIRECT_COST';
export const DELETE_INIT_INDIRECT_COST_SUCCESS =
  'DELETE_INIT_INDIRECT_COST_SUCCESS';
export const DELETE_INIT_INDIRECT_COST_ERROR =
  'DELETE_INIT_INDIRECT_COST_ERROR';
export const GET_PRODUCTIONS = 'GET_PRODUCTIONS';
export const GET_PRODUCTIONS_SUCCESS = 'GET_PRODUCTIONS_SUCCESS';
export const GET_PRODUCTIONS_ERROR = 'GET_PRODUCTIONS_ERROR';
export const SET_PRODUCTION = 'SET_PRODUCTION';
export const SET_PRODUCTION_SUCCESS = 'SET_PRODUCTION_SUCCESS';
export const SET_PRODUCTION_ERROR = 'SET_PRODUCTION_ERROR';
export const CLOSE_PRODUCTION_MONTH = 'CLOSE_PRODUCTION_MONTH';
export const CLOSE_PRODUCTION_MONTH_SUCCESS = 'CLOSE_PRODUCTION_MONTH_SUCCESS';
export const CLOSE_PRODUCTION_MONTH_ERROR = 'CLOSE_PRODUCTION_MONTH_ERROR';
export const GET_PROJECT_TIMELINES = 'GET_PROJECT_TIMELINES';
export const GET_PROJECT_TIMELINES_SUCCESS = 'GET_PROJECT_TIMELINES_SUCCESS';
export const GET_PROJECT_TIMELINES_ERROR = 'GET_PROJECT_TIMELINES_ERROR';
export const GET_INDIRECT_COSTS = 'GET_INDIRECT_COSTS';
export const GET_INDIRECT_COSTS_SUCCESS = 'GET_INDIRECT_COSTS_SUCCESS';
export const GET_INDIRECT_COSTS_ERROR = 'GET_INDIRECT_COSTS_ERROR';
export const ADD_INDIRECT_COST = 'ADD_INDIRECT_COST';
export const ADD_INDIRECT_COST_SUCCESS = 'ADD_INDIRECT_COST_SUCCESS';
export const ADD_INDIRECT_COST_ERROR = 'ADD_INDIRECT_COST_ERROR';
export const UPDATE_INDIRECT_COST = 'UPDATE_INDIRECT_COST';
export const UPDATE_INDIRECT_COST_SUCCESS = 'UPDATE_INDIRECT_COST_SUCCESS';
export const UPDATE_INDIRECT_COST_ERROR = 'UPDATE_INDIRECT_COST_ERROR';
export const DEL_INDIRECT_COST = 'DEL_INDIRECT_COST';
export const DEL_INDIRECT_COST_SUCCESS = 'DEL_INDIRECT_COST_SUCCESS';
export const DEL_INDIRECT_COST_ERROR = 'DEL_INDIRECT_COST_ERROR';
export const GET_DELIVERYNOTES = 'GET_DELIVERYNOTES';
export const GET_DELIVERYNOTES_SUCCESS = 'GET_DELIVERYNOTES_SUCCESS';
export const GET_DELIVERYNOTES_ERROR = 'GET_DELIVERYNOTES_ERROR';
export const ADD_DELIVERYNOTE = 'ADD_DELIVERYNOTE';
export const ADD_DELIVERYNOTE_SUCCESS = 'ADD_DELIVERYNOTE_SUCCESS';
export const ADD_DELIVERYNOTE_ERROR = 'ADD_DELIVERYNOTE_ERROR';
export const UPDATE_DELIVERYNOTE = 'UPDATE_DELIVERYNOTE';
export const UPDATE_DELIVERYNOTE_SUCCESS = 'UPDATE_DELIVERYNOTE_SUCCESS';
export const UPDATE_DELIVERYNOTE_ERROR = 'UPDATE_DELIVERYNOTE_ERROR';
export const DEL_DELIVERYNOTE = 'DEL_DELIVERYNOTE';
export const DEL_DELIVERYNOTE_SUCCESS = 'DEL_DELIVERYNOTE_SUCCESS';
export const DEL_DELIVERYNOTE_ERROR = 'DEL_DELIVERYNOTE_ERROR';
export const GET_SUBCONTRACTORS = 'GET_SUBCONTRACTORS';
export const GET_SUBCONTRACTORS_SUCCESS = 'GET_SUBCONTRACTORS_SUCCESS';
export const GET_SUBCONTRACTORS_ERROR = 'GET_SUBCONTRACTORS_ERROR';
export const ADD_SUBCONTRACTOR = 'ADD_SUBCONTRACTOR';
export const ADD_SUBCONTRACTOR_SUCCESS = 'ADD_SUBCONTRACTOR_SUCCESS';
export const ADD_SUBCONTRACTOR_ERROR = 'ADD_SUBCONTRACTOR_ERROR';
export const UPDATE_SUBCONTRACTOR = 'UPDATE_SUBCONTRACTOR';
export const UPDATE_SUBCONTRACTOR_SUCCESS = 'UPDATE_SUBCONTRACTOR_SUCCESS';
export const UPDATE_SUBCONTRACTOR_ERROR = 'UPDATE_SUBCONTRACTOR_ERROR';
export const DEL_SUBCONTRACTOR = 'DEL_SUBCONTRACTOR';
export const DEL_SUBCONTRACTOR_SUCCESS = 'DEL_SUBCONTRACTOR_SUCCESS';
export const DEL_SUBCONTRACTOR_ERROR = 'DEL_SUBCONTRACTOR_ERROR';
export const UPDATE_PROJECT_GLOBALS = 'UPDATE_PROJECT_GLOBALS';
export const CLOSE_INITIAL_STUDY = 'CLOSE_INITIAL_STUDY';
export const CLOSE_INITIAL_STUDY_SUCCESS = 'CLOSE_INITIAL_STUDY_SUCCESS';
export const CLOSE_INITIAL_STUDY_ERROR = 'CLOSE_INITIAL_STUDY_ERROR';
export const APPROVE_INITIAL_STUDY = 'APPROVE_INITIAL_STUDY';
export const APPROVE_INITIAL_STUDY_SUCCESS = 'APPROVE_INITIAL_STUDY_SUCCESS';
export const APPROVE_INITIAL_STUDY_ERROR = 'APPROVE_INITIAL_STUDY_ERROR';
export const REVISE_INITIAL_STUDY = 'REVISE_INITIAL_STUDY';
export const REVISE_INITIAL_STUDY_SUCCESS = 'REVISE_INITIAL_STUDY_SUCCESS';
export const REVISE_INITIAL_STUDY_ERROR = 'REVISE_INITIAL_STUDY_ERROR';
export const CLOSE_REVISED_INITIAL_STUDY = 'CLOSE_REVISED_INITIAL_STUDY';
export const CLOSE_REVISED_INITIAL_STUDY_SUCCESS =
  'CLOSE_REVISED_INITIAL_STUDY_SUCCESS';
export const CLOSE_REVISED_INITIAL_STUDY_ERROR =
  'CLOSE_REVISED_INITIAL_STUDY_ERROR';
export const START_CONSTRUCTION = 'START_CONSTRUCTION';
export const START_CONSTRUCTION_SUCCESS = 'START_CONSTRUCTION_SUCCESS';
export const START_CONSTRUCTION_ERROR = 'START_CONSTRUCTION_ERROR';
export const CANCEL_CONSTRUCTION = 'CANCEL_CONSTRUCTION';
export const CANCEL_CONSTRUCTION_SUCCESS = 'CANCEL_CONSTRUCTION_SUCCESS';
export const CANCEL_CONSTRUCTION_ERROR = 'CANCEL_CONSTRUCTION_ERROR';
export const FINISH_CONSTRUCTION = 'FINISH_CONSTRUCTION';
export const FINISH_CONSTRUCTION_SUCCESS = 'FINISH_CONSTRUCTION_SUCCESS';
export const FINISH_CONSTRUCTION_ERROR = 'FINISH_CONSTRUCTION_ERROR';

export default {
  menu,
  settings,
  actionConfirmer,
  auth,
  projects,
  projectDetail,
};
