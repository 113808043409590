/* eslint-disable import/no-cycle */
import {
  GET_PROJECT_DETAIL,
  GET_PROJECT_DETAIL_SUCCESS,
  GET_PROJECT_DETAIL_ERROR,
  ADD_PROJECT_PRESUPOSITION,
  ADD_PROJECT_PRESUPOSITION_ERROR,
  ADD_PROJECT_EXTENSION,
  ADD_PROJECT_EXTENSION_ERROR,
  DELETE_DETAIL_PROJECT,
  DELETE_DETAIL_PROJECT_SUCCESS,
  DELETE_DETAIL_PROJECT_ERROR,
  SET_INIT_DIRECT_COST,
  SET_INIT_DIRECT_COST_SUCCESS,
  SET_INIT_DIRECT_COST_ERROR,
  UPDATE_INIT_DIRECT_COST,
  UPDATE_INIT_DIRECT_COST_SUCCESS,
  UPDATE_INIT_DIRECT_COST_ERROR,
  SET_SUBDIRECT_COST,
  SET_SUBDIRECT_COST_SUCCESS,
  SET_SUBDIRECT_COST_ERROR,
  UPDATE_SUBDIRECT_COST,
  UPDATE_SUBDIRECT_COST_SUCCESS,
  UPDATE_SUBDIRECT_COST_ERROR,
  DELETE_SUBDIRECT_COST,
  DELETE_SUBDIRECT_COST_SUCCESS,
  DELETE_SUBDIRECT_COST_ERROR,
  SET_INIT_INDIRECT_COST,
  SET_INIT_INDIRECT_COST_SUCCESS,
  SET_INIT_INDIRECT_COST_ERROR,
  UPDATE_INIT_INDIRECT_COST,
  UPDATE_INIT_INDIRECT_COST_SUCCESS,
  UPDATE_INIT_INDIRECT_COST_ERROR,
  DELETE_INIT_INDIRECT_COST,
  DELETE_INIT_INDIRECT_COST_SUCCESS,
  DELETE_INIT_INDIRECT_COST_ERROR,
  GET_PRODUCTIONS,
  GET_PRODUCTIONS_SUCCESS,
  GET_PRODUCTIONS_ERROR,
  SET_PRODUCTION,
  SET_PRODUCTION_SUCCESS,
  SET_PRODUCTION_ERROR,
  CLOSE_PRODUCTION_MONTH,
  CLOSE_PRODUCTION_MONTH_SUCCESS,
  CLOSE_PRODUCTION_MONTH_ERROR,
  GET_PROJECT_TIMELINES,
  GET_PROJECT_TIMELINES_SUCCESS,
  GET_PROJECT_TIMELINES_ERROR,
  GET_INDIRECT_COSTS,
  GET_INDIRECT_COSTS_SUCCESS,
  GET_INDIRECT_COSTS_ERROR,
  ADD_INDIRECT_COST,
  ADD_INDIRECT_COST_SUCCESS,
  ADD_INDIRECT_COST_ERROR,
  UPDATE_INDIRECT_COST,
  UPDATE_INDIRECT_COST_SUCCESS,
  UPDATE_INDIRECT_COST_ERROR,
  DEL_INDIRECT_COST,
  DEL_INDIRECT_COST_SUCCESS,
  DEL_INDIRECT_COST_ERROR,
  GET_DELIVERYNOTES,
  GET_DELIVERYNOTES_SUCCESS,
  GET_DELIVERYNOTES_ERROR,
  ADD_DELIVERYNOTE,
  ADD_DELIVERYNOTE_SUCCESS,
  ADD_DELIVERYNOTE_ERROR,
  UPDATE_DELIVERYNOTE,
  UPDATE_DELIVERYNOTE_SUCCESS,
  UPDATE_DELIVERYNOTE_ERROR,
  DEL_DELIVERYNOTE,
  DEL_DELIVERYNOTE_SUCCESS,
  DEL_DELIVERYNOTE_ERROR,
  GET_SUBCONTRACTORS,
  GET_SUBCONTRACTORS_SUCCESS,
  GET_SUBCONTRACTORS_ERROR,
  ADD_SUBCONTRACTOR,
  ADD_SUBCONTRACTOR_SUCCESS,
  ADD_SUBCONTRACTOR_ERROR,
  UPDATE_SUBCONTRACTOR,
  UPDATE_SUBCONTRACTOR_SUCCESS,
  UPDATE_SUBCONTRACTOR_ERROR,
  DEL_SUBCONTRACTOR,
  DEL_SUBCONTRACTOR_SUCCESS,
  DEL_SUBCONTRACTOR_ERROR,
  UPDATE_PROJECT_GLOBALS,
  CLOSE_INITIAL_STUDY,
  CLOSE_INITIAL_STUDY_SUCCESS,
  CLOSE_INITIAL_STUDY_ERROR,
  APPROVE_INITIAL_STUDY,
  APPROVE_INITIAL_STUDY_SUCCESS,
  APPROVE_INITIAL_STUDY_ERROR,
  REVISE_INITIAL_STUDY,
  REVISE_INITIAL_STUDY_SUCCESS,
  REVISE_INITIAL_STUDY_ERROR,
  CLOSE_REVISED_INITIAL_STUDY,
  CLOSE_REVISED_INITIAL_STUDY_SUCCESS,
  CLOSE_REVISED_INITIAL_STUDY_ERROR,
  START_CONSTRUCTION,
  START_CONSTRUCTION_SUCCESS,
  START_CONSTRUCTION_ERROR,
  CANCEL_CONSTRUCTION,
  CANCEL_CONSTRUCTION_SUCCESS,
  CANCEL_CONSTRUCTION_ERROR,
  FINISH_CONSTRUCTION,
  FINISH_CONSTRUCTION_SUCCESS,
  FINISH_CONSTRUCTION_ERROR,
} from '../actions';

export const getProjectDetail = (id) => ({
  type: GET_PROJECT_DETAIL,
  payload: id,
});
export const getProjectDetailSuccess = (project) => ({
  type: GET_PROJECT_DETAIL_SUCCESS,
  payload: project,
});
export const getProjectDetailError = (error) => ({
  type: GET_PROJECT_DETAIL_ERROR,
  payload: error,
});

export const addProjectPresuposition = (projectData) => ({
  type: ADD_PROJECT_PRESUPOSITION,
  payload: projectData,
});
export const addProjectPresupositionError = (error) => ({
  type: ADD_PROJECT_PRESUPOSITION_ERROR,
  payload: error,
});

export const deleteProjectDetail = ({ id, callback }) => ({
  type: DELETE_DETAIL_PROJECT,
  payload: { id, callback },
});
export const deleteProjectDetailSuccess = () => ({
  type: DELETE_DETAIL_PROJECT_SUCCESS,
});
export const deleteProjectDetailError = (error) => ({
  type: DELETE_DETAIL_PROJECT_ERROR,
  payload: error,
});

export const setDirectCost = ({ costData, callback }) => ({
  type: SET_INIT_DIRECT_COST,
  payload: { costData, callback },
});
export const setDirectCostSuccess = (newCost) => ({
  type: SET_INIT_DIRECT_COST_SUCCESS,
  payload: newCost,
});
export const setDirectCostError = (error) => ({
  type: SET_INIT_DIRECT_COST_ERROR,
  payload: error,
});

export const updateDirectCost = ({ costData, callback }) => ({
  type: UPDATE_INIT_DIRECT_COST,
  payload: { costData, callback },
});
export const updateDirectCostSuccess = (newCost) => ({
  type: UPDATE_INIT_DIRECT_COST_SUCCESS,
  payload: newCost,
});
export const updateDirectCostError = (error) => ({
  type: UPDATE_INIT_DIRECT_COST_ERROR,
  payload: error,
});

export const setSubDirectCost = ({ costData, callback }) => ({
  type: SET_SUBDIRECT_COST,
  payload: { costData, callback },
});
export const setSubDirectCostSuccess = (newCost) => ({
  type: SET_SUBDIRECT_COST_SUCCESS,
  payload: newCost,
});
export const setSubDirectCostError = (error) => ({
  type: SET_SUBDIRECT_COST_ERROR,
  payload: error,
});

export const updateSubDirectCost = ({ costData, callback }) => ({
  type: UPDATE_SUBDIRECT_COST,
  payload: { costData, callback },
});
export const updateSubDirectCostSuccess = (newCost) => ({
  type: UPDATE_SUBDIRECT_COST_SUCCESS,
  payload: newCost,
});
export const updateSubDirectCostError = (error) => ({
  type: UPDATE_SUBDIRECT_COST_ERROR,
  payload: error,
});

export const deleteSubDirectCost = (costData) => ({
  type: DELETE_SUBDIRECT_COST,
  payload: costData,
});
export const deleteSubDirectCostSuccess = (costData) => ({
  type: DELETE_SUBDIRECT_COST_SUCCESS,
  payload: costData,
});
export const deleteSubDirectCostError = (error) => ({
  type: DELETE_SUBDIRECT_COST_ERROR,
  payload: error,
});

export const setIndirectCost = ({ costData, callback }) => ({
  type: SET_INIT_INDIRECT_COST,
  payload: { costData, callback },
});
export const setIndirectCostSuccess = (newCost) => ({
  type: SET_INIT_INDIRECT_COST_SUCCESS,
  payload: newCost,
});
export const setIndirectCostError = (error) => ({
  type: SET_INIT_INDIRECT_COST_ERROR,
  payload: error,
});

export const updateIndirectCost = ({ costData, callback }) => ({
  type: UPDATE_INIT_INDIRECT_COST,
  payload: { costData, callback },
});
export const updateIndirectCostSuccess = (newCost) => ({
  type: UPDATE_INIT_INDIRECT_COST_SUCCESS,
  payload: newCost,
});
export const updateIndirectCostError = (error) => ({
  type: UPDATE_INIT_INDIRECT_COST_ERROR,
  payload: error,
});

export const deleteIndirectCost = (costData) => ({
  type: DELETE_INIT_INDIRECT_COST,
  payload: costData,
});
export const deleteIndirectCostSuccess = (costData) => ({
  type: DELETE_INIT_INDIRECT_COST_SUCCESS,
  payload: costData,
});
export const deleteIndirectCostError = (error) => ({
  type: DELETE_INIT_INDIRECT_COST_ERROR,
  payload: error,
});

export const getProjectProductions = (idProject) => ({
  type: GET_PRODUCTIONS,
  payload: idProject,
});
export const getProjectProductionsSuccess = (projectProduction) => ({
  type: GET_PRODUCTIONS_SUCCESS,
  payload: projectProduction,
});
export const getProjectProductionsError = (error) => ({
  type: GET_PRODUCTIONS_ERROR,
  payload: error,
});

export const setProjectProduction = ({ productionData, callback }) => ({
  type: SET_PRODUCTION,
  payload: { productionData, callback },
});
export const setProjectProductionSuccess = (newProduction) => ({
  type: SET_PRODUCTION_SUCCESS,
  payload: newProduction,
});
export const setProjectProductionError = (error) => ({
  type: SET_PRODUCTION_ERROR,
  payload: error,
});

export const closeProjectProductionMonth = ({ id, callback }) => ({
  type: CLOSE_PRODUCTION_MONTH,
  payload: { id, callback },
});
export const closeProductionMonthSuccess = () => ({
  type: CLOSE_PRODUCTION_MONTH_SUCCESS,
});
export const closeProductionMonthError = (error) => ({
  type: CLOSE_PRODUCTION_MONTH_ERROR,
  payload: error,
});

export const getProjectTimeline = (idProject) => ({
  type: GET_PROJECT_TIMELINES,
  payload: idProject,
});

export const getProjectTimelineSuccess = (projectTimelines) => ({
  type: GET_PROJECT_TIMELINES_SUCCESS,
  payload: projectTimelines,
});

export const getProjectTimelineError = (error) => ({
  type: GET_PROJECT_TIMELINES_ERROR,
  payload: error,
});

export const getProjectIndirectCosts = ({ idProject, idTimeline, mode }) => ({
  type: GET_INDIRECT_COSTS,
  payload: { idProject, idTimeline, mode },
});

export const getProjectIndirectCostsSuccess = (projectIndirectCosts) => ({
  type: GET_INDIRECT_COSTS_SUCCESS,
  payload: projectIndirectCosts,
});

export const getProjectIndirectCostsError = (error) => ({
  type: GET_INDIRECT_COSTS_ERROR,
  payload: error,
});

export const addProjectIndirectCost = ({ costData, callback }) => ({
  type: ADD_INDIRECT_COST,
  payload: { costData, callback },
});

export const addProjectIndirectCostSuccess = (newCost) => ({
  type: ADD_INDIRECT_COST_SUCCESS,
  payload: { newCost },
});

export const addProjectIndirectCostError = (error) => ({
  type: ADD_INDIRECT_COST_ERROR,
  payload: error,
});

export const updateProjectIndirectCost = ({
  costData,
  callback,
  isAllMode,
}) => ({
  type: UPDATE_INDIRECT_COST,
  payload: { costData, callback, isAllMode },
});

export const updateProjectIndirectCostSuccess = (updatedCost, isAllMode) => ({
  type: UPDATE_INDIRECT_COST_SUCCESS,
  payload: { updatedCost, isAllMode },
});

export const updateProjectIndirectCostError = (error) => ({
  type: UPDATE_INDIRECT_COST_ERROR,
  payload: error,
});

export const delProjectIndirectCost = ({ idProject, idCost, isAllMode }) => ({
  type: DEL_INDIRECT_COST,
  payload: { idProject, idCost, isAllMode },
});

export const delProjectIndirectCostSuccess = (
  idIndirectCost,
  data,
  isAllMode
) => ({
  type: DEL_INDIRECT_COST_SUCCESS,
  payload: { idIndirectCost, data, isAllMode },
});

export const delProjectIndirectCostError = (error) => ({
  type: DEL_INDIRECT_COST_ERROR,
  payload: error,
});

export const getProjectDeliveryNotes = ({ idProject, idTimeline, mode }) => ({
  type: GET_DELIVERYNOTES,
  payload: { idProject, idTimeline, mode },
});

export const getProjectDeliveryNotesSuccess = (projectDeliveries) => ({
  type: GET_DELIVERYNOTES_SUCCESS,
  payload: projectDeliveries,
});

export const getProjectDeliveryNotesError = (error) => ({
  type: GET_DELIVERYNOTES_ERROR,
  payload: error,
});

export const addProjectDeliveryNote = ({ deliveryData, callback }) => ({
  type: ADD_DELIVERYNOTE,
  payload: { deliveryData, callback },
});

export const addProjectDeliveryNoteSuccess = (newDelivery) => ({
  type: ADD_DELIVERYNOTE_SUCCESS,
  payload: { newDelivery },
});

export const addProjectDeliveryNoteError = (error) => ({
  type: ADD_DELIVERYNOTE_ERROR,
  payload: error,
});

export const updateProjectDeliveryNote = ({
  deliveryData,
  callback,
  isAllMode,
}) => ({
  type: UPDATE_DELIVERYNOTE,
  payload: { deliveryData, callback, isAllMode },
});

export const updateProjectDeliveryNoteSuccess = (
  updatedDelivery,
  isAllMode
) => ({
  type: UPDATE_DELIVERYNOTE_SUCCESS,
  payload: { updatedDelivery, isAllMode },
});

export const updateProjectDeliveryNoteError = (error) => ({
  type: UPDATE_DELIVERYNOTE_ERROR,
  payload: error,
});

export const delProjectDeliveryNote = ({
  idProject,
  idDelivery,
  isAllMode,
}) => ({
  type: DEL_DELIVERYNOTE,
  payload: { idProject, idDelivery, isAllMode },
});

export const delProjectDeliveryNoteSuccess = (idDelivery, data, isAllMode) => ({
  type: DEL_DELIVERYNOTE_SUCCESS,
  payload: { idDelivery, data, isAllMode },
});

export const delProjectDeliveryNoteError = (error) => ({
  type: DEL_DELIVERYNOTE_ERROR,
  payload: error,
});

export const getProjectSubcontractors = ({ idProject, idTimeline, mode }) => ({
  type: GET_SUBCONTRACTORS,
  payload: { idProject, idTimeline, mode },
});

export const getProjectSubcontractorsSuccess = (projectSubcontractors) => ({
  type: GET_SUBCONTRACTORS_SUCCESS,
  payload: projectSubcontractors,
});

export const getProjectSubcontractorsError = (error) => ({
  type: GET_SUBCONTRACTORS_ERROR,
  payload: error,
});

export const addProjectSubcontractor = ({ subcontractorData, callback }) => ({
  type: ADD_SUBCONTRACTOR,
  payload: { subcontractorData, callback },
});

export const addProjectSubcontractorSuccess = (newSubcontractor) => ({
  type: ADD_SUBCONTRACTOR_SUCCESS,
  payload: { newSubcontractor },
});

export const addProjectSubcontractorError = (error) => ({
  type: ADD_SUBCONTRACTOR_ERROR,
  payload: error,
});

export const updateProjectSubcontractor = ({
  subcontractorData,
  callback,
  isAllMode,
}) => ({
  type: UPDATE_SUBCONTRACTOR,
  payload: { subcontractorData, callback, isAllMode },
});

export const updateProjectSubcontractorSuccess = (
  updatedSubcontractor,
  isAllMode
) => ({
  type: UPDATE_SUBCONTRACTOR_SUCCESS,
  payload: { updatedSubcontractor, isAllMode },
});

export const updateProjectSubcontractorError = (error) => ({
  type: UPDATE_SUBCONTRACTOR_ERROR,
  payload: error,
});

export const delProjectSubcontractor = ({
  idProject,
  idSubcontractor,
  isAllMode,
}) => ({
  type: DEL_SUBCONTRACTOR,
  payload: { idProject, idSubcontractor, isAllMode },
});

export const delProjectSubcontractorSuccess = (
  idSubcontractor,
  data,
  isAllMode
) => ({
  type: DEL_SUBCONTRACTOR_SUCCESS,
  payload: { idSubcontractor, data, isAllMode },
});

export const delProjectSubcontractorError = (error) => ({
  type: DEL_SUBCONTRACTOR_ERROR,
  payload: error,
});

export const updateProjectGlobals = (newGlobals) => ({
  type: UPDATE_PROJECT_GLOBALS,
  payload: newGlobals,
});

export const closeInitialStudy = ({ id, callback }) => ({
  type: CLOSE_INITIAL_STUDY,
  payload: { id, callback },
});
export const closeInitialStudySuccess = () => ({
  type: CLOSE_INITIAL_STUDY_SUCCESS,
});
export const closeInitialStudyError = (error) => ({
  type: CLOSE_INITIAL_STUDY_ERROR,
  payload: error,
});

export const approveInitialStudy = ({ id, callback }) => ({
  type: APPROVE_INITIAL_STUDY,
  payload: { id, callback },
});
export const approveInitialStudySuccess = () => ({
  type: APPROVE_INITIAL_STUDY_SUCCESS,
});
export const approveInitialStudyError = (error) => ({
  type: APPROVE_INITIAL_STUDY_ERROR,
  payload: error,
});

export const reviseInitialStudy = ({ id, callback }) => ({
  type: REVISE_INITIAL_STUDY,
  payload: { id, callback },
});
export const reviseInitialStudySuccess = () => ({
  type: REVISE_INITIAL_STUDY_SUCCESS,
});
export const reviseInitialStudyError = (error) => ({
  type: REVISE_INITIAL_STUDY_ERROR,
  payload: error,
});

export const closeRevisedInitialStudy = ({ id, callback }) => ({
  type: CLOSE_REVISED_INITIAL_STUDY,
  payload: { id, callback },
});
export const closeRevisedInitialStudySuccess = () => ({
  type: CLOSE_REVISED_INITIAL_STUDY_SUCCESS,
});
export const closeRevisedInitialStudyError = (error) => ({
  type: CLOSE_REVISED_INITIAL_STUDY_ERROR,
  payload: error,
});

export const startConstruction = ({ id, callback }) => ({
  type: START_CONSTRUCTION,
  payload: { id, callback },
});
export const startConstructionSuccess = () => ({
  type: START_CONSTRUCTION_SUCCESS,
});
export const startConstructionError = (error) => ({
  type: START_CONSTRUCTION_ERROR,
  payload: error,
});

export const cancelConstruction = ({ id, callback }) => ({
  type: CANCEL_CONSTRUCTION,
  payload: { id, callback },
});
export const cancelConstructionSuccess = () => ({
  type: CANCEL_CONSTRUCTION_SUCCESS,
});
export const cancelConstructionError = (error) => ({
  type: CANCEL_CONSTRUCTION_ERROR,
  payload: error,
});

export const finishConstruction = ({ id, callback }) => ({
  type: FINISH_CONSTRUCTION,
  payload: { id, callback },
});
export const finishConstructionSuccess = () => ({
  type: FINISH_CONSTRUCTION_SUCCESS,
});
export const finishConstructionError = (error) => ({
  type: FINISH_CONSTRUCTION_ERROR,
  payload: error,
});
export const addProjectExtension = (projectData) => ({
  type: ADD_PROJECT_EXTENSION,
  payload: projectData,
});
export const addProjectExtensionError = (error) => ({
  type: ADD_PROJECT_EXTENSION_ERROR,
  payload: error,
});
