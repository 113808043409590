import {
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_ERROR,
} from '../actions';

const INIT_STATE = {
  data: [],
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTS:
      return { ...state, data: [], loading: true, error: '' };
    case GET_PROJECTS_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: '' };
    case GET_PROJECTS_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
