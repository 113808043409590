// eslint-disable-next-line import/no-cycle
import {
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_ERROR,
} from '../actions';

export const getProjects = () => ({ type: GET_PROJECTS });
export const getProjectsSuccess = (projects) => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects,
});
export const getProjectsError = (error) => ({
  type: GET_PROJECTS_ERROR,
  payload: error,
});
