import { all, fork, put, takeEvery } from 'redux-saga/effects';

import eCobraApi from '@src/api';
import Actions, { GET_PROJECTS } from '@src/redux/actions';

function* watchGetProjects() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_PROJECTS, getProjectsStart);
}
function* getProjectsStart() {
  try {
    const { data } = yield eCobraApi.getProjects();
    yield put(Actions.projects.getProjectsSuccess(data));
  } catch (error) {
    yield put(Actions.projects.getProjectsError(error));
  }
}

export default function* rootSaga() {
  yield all([fork(watchGetProjects)]);
}
