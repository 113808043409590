import { all, fork, put, takeEvery } from 'redux-saga/effects';

import eCobraApi from '@src/api';
import Actions, {
  GET_PROJECT_DETAIL,
  DELETE_DETAIL_PROJECT,
  ADD_PROJECT_PRESUPOSITION,
  SET_INIT_DIRECT_COST,
  UPDATE_INIT_DIRECT_COST,
  SET_SUBDIRECT_COST,
  UPDATE_SUBDIRECT_COST,
  DELETE_SUBDIRECT_COST,
  SET_INIT_INDIRECT_COST,
  UPDATE_INIT_INDIRECT_COST,
  DELETE_INIT_INDIRECT_COST,
  GET_PRODUCTIONS,
  SET_PRODUCTION,
  CLOSE_PRODUCTION_MONTH,
  GET_PROJECT_TIMELINES,
  GET_INDIRECT_COSTS,
  ADD_INDIRECT_COST,
  UPDATE_INDIRECT_COST,
  DEL_INDIRECT_COST,
  GET_DELIVERYNOTES,
  ADD_DELIVERYNOTE,
  UPDATE_DELIVERYNOTE,
  DEL_DELIVERYNOTE,
  GET_SUBCONTRACTORS,
  ADD_SUBCONTRACTOR,
  UPDATE_SUBCONTRACTOR,
  DEL_SUBCONTRACTOR,
  CLOSE_INITIAL_STUDY,
  APPROVE_INITIAL_STUDY,
  REVISE_INITIAL_STUDY,
  CLOSE_REVISED_INITIAL_STUDY,
  START_CONSTRUCTION,
  CANCEL_CONSTRUCTION,
  FINISH_CONSTRUCTION, ADD_PROJECT_EXTENSION,
} from '@src/redux/actions';

function* watchGetProjectDetail() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_PROJECT_DETAIL, getProjectDetailStart);
}
function* getProjectDetailStart({ payload }) {
  try {
    const { data } = yield eCobraApi.getProjectPresupposition(payload);
    yield put(Actions.projectDetail.getProjectDetailSuccess(data));
  } catch (error) {
    yield put(Actions.projectDetail.getProjectDetailError(error));
  }
}

function* watchDeleteProjectDetail() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DELETE_DETAIL_PROJECT, deleteProjectDetailStart);
}
function* deleteProjectDetailStart({ payload }) {
  try {
    yield eCobraApi.deleteProject(payload.id);
    yield put(Actions.projectDetail.deleteProjectDetailSuccess());
    if (payload.callback) payload.callback();
  } catch (error) {
    yield put(Actions.projectDetail.deleteProjectDetailError(error));
  }
}

function* watchAddProjectPresuposition() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(ADD_PROJECT_PRESUPOSITION, addProjectPresupositionStart);
}
function* addProjectPresupositionStart({ payload }) {
  try {
    const {
      proyecto: { id_proyecto: idProject },
    } = yield eCobraApi.addProjectPresuposition(payload);
    yield put(Actions.projectDetail.getProjectDetail(idProject));
  } catch (error) {
    yield put(Actions.projectDetail.addProjectPresupositionError(error));
  }
}
function* watchAddProjectExtension() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(ADD_PROJECT_EXTENSION, addProjectExtensionStart);
}
function* addProjectExtensionStart({ payload }) {
  try {
    const {
      proyecto: { id_proyecto: idProject },
    } = yield eCobraApi.addProjectExtension(payload);
    yield put(Actions.projectDetail.getProjectDetail(idProject));
  } catch (error) {
    yield put(Actions.projectDetail.addProjectExtensionError(error));
  }
}

function* watchSetDirectCost() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SET_INIT_DIRECT_COST, setDirectCostStart);
}
function* setDirectCostStart({ payload: { costData, callback } }) {
  try {
    const {
      data: {
        registro,
        costeTotalProyecto,
        resultadoProyecto,
        totalCostesDirectos,
        totalCostesIndirectosEstudio,
        totalPresupuesto,
      },
    } = yield eCobraApi.setProjectPresuppositionDirectCost(costData);
    yield put(Actions.projectDetail.setDirectCostSuccess(registro));
    yield put(
      Actions.projectDetail.updateProjectGlobals({
        costeTotalProyecto,
        resultadoProyecto,
        totalCostesDirectos,
        totalCostesIndirectosEstudio,
        totalPresupuesto,
      })
    );
  } catch (error) {
    yield put(Actions.projectDetail.setDirectCostError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchUpdateDirectCost() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_INIT_DIRECT_COST, updateDirectCostStart);
}
function* updateDirectCostStart({ payload: { costData, callback } }) {
  try {
    const {
      data: {
        registro,
        costeTotalProyecto,
        resultadoProyecto,
        totalCostesDirectos,
        totalCostesIndirectosEstudio,
        totalPresupuesto,
      },
    } = yield eCobraApi.updateProjectPresuppositionDirectCost(costData);
    yield put(Actions.projectDetail.updateDirectCostSuccess(registro));
    yield put(
      Actions.projectDetail.updateProjectGlobals({
        costeTotalProyecto,
        resultadoProyecto,
        totalCostesDirectos,
        totalCostesIndirectosEstudio,
        totalPresupuesto,
      })
    );
  } catch (error) {
    yield put(Actions.projectDetail.updateDirectCostError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchSetSubDirectCost() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SET_SUBDIRECT_COST, setSubDirectCostStart);
}
function* setSubDirectCostStart({ payload: { costData, callback } }) {
  try {
    const {
      data: {
        registro,
        costeTotalProyecto,
        resultadoProyecto,
        totalCostesDirectos,
        totalCostesIndirectosEstudio,
        totalPresupuesto,
      },
    } = yield eCobraApi.setProjectPresuppositionDirectCost(costData);
    yield put(Actions.projectDetail.setSubDirectCostSuccess(registro));
    yield put(
      Actions.projectDetail.updateProjectGlobals({
        costeTotalProyecto,
        resultadoProyecto,
        totalCostesDirectos,
        totalCostesIndirectosEstudio,
        totalPresupuesto,
      })
    );
  } catch (error) {
    yield put(Actions.projectDetail.setSubDirectCostError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchUpdateSubDirectCost() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_SUBDIRECT_COST, updateSubDirectCostStart);
}
function* updateSubDirectCostStart({ payload: { costData, callback } }) {
  try {
    const {
      data: {
        registro,
        costeTotalProyecto,
        resultadoProyecto,
        totalCostesDirectos,
        totalCostesIndirectosEstudio,
        totalPresupuesto,
      },
    } = yield eCobraApi.updateProjectPresuppositionDirectCost(costData);
    yield put(Actions.projectDetail.updateSubDirectCostSuccess(registro));
    yield put(
      Actions.projectDetail.updateProjectGlobals({
        costeTotalProyecto,
        resultadoProyecto,
        totalCostesDirectos,
        totalCostesIndirectosEstudio,
        totalPresupuesto,
      })
    );
  } catch (error) {
    yield put(Actions.projectDetail.updateSubDirectCostError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchDeleteSubDirectCost() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DELETE_SUBDIRECT_COST, deleteSubDirectCostStart);
}
function* deleteSubDirectCostStart({ payload }) {
  try {
    const {
      data: {
        costeTotalProyecto,
        resultadoProyecto,
        totalCostesDirectos,
        totalCostesIndirectosEstudio,
        totalPresupuesto,
      },
    } = yield eCobraApi.deleteProjectPresuppositionDirectCost(payload);
    yield put(Actions.projectDetail.deleteSubDirectCostSuccess(payload));
    yield put(
      Actions.projectDetail.updateProjectGlobals({
        costeTotalProyecto,
        resultadoProyecto,
        totalCostesDirectos,
        totalCostesIndirectosEstudio,
        totalPresupuesto,
      })
    );
  } catch (error) {
    yield put(Actions.projectDetail.deleteSubDirectCostError(error));
  }
}

function* watchSetIndirectCost() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SET_INIT_INDIRECT_COST, setIndirectCostStart);
}
function* setIndirectCostStart({ payload: { costData, callback } }) {
  try {
    const {
      data: {
        registro,
        costeTotalProyecto,
        resultadoProyecto,
        totalCostesDirectos,
        totalCostesIndirectosEstudio,
        totalPresupuesto,
      },
    } = yield eCobraApi.setProjectPresuppositionIndirectCost(costData);
    yield put(Actions.projectDetail.setIndirectCostSuccess(registro));
    yield put(
      Actions.projectDetail.updateProjectGlobals({
        costeTotalProyecto,
        resultadoProyecto,
        totalCostesDirectos,
        totalCostesIndirectosEstudio,
        totalPresupuesto,
      })
    );
  } catch (error) {
    yield put(Actions.projectDetail.setIndirectCostError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchUpdateIndirectCost() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_INIT_INDIRECT_COST, updateIndirectCostStart);
}
function* updateIndirectCostStart({ payload: { costData, callback } }) {
  try {
    const {
      data: {
        registro,
        costeTotalProyecto,
        resultadoProyecto,
        totalCostesDirectos,
        totalCostesIndirectosEstudio,
        totalPresupuesto,
      },
    } = yield eCobraApi.updateProjectPresuppositionIndirectCost(costData);
    yield put(Actions.projectDetail.updateIndirectCostSuccess(registro));
    yield put(
      Actions.projectDetail.updateProjectGlobals({
        costeTotalProyecto,
        resultadoProyecto,
        totalCostesDirectos,
        totalCostesIndirectosEstudio,
        totalPresupuesto,
      })
    );
  } catch (error) {
    yield put(Actions.projectDetail.updateIndirectCostError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchDeleteIndirectCost() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DELETE_INIT_INDIRECT_COST, deleteIndirectCostStart);
}
function* deleteIndirectCostStart({ payload }) {
  try {
    const {
      data: {
        costeTotalProyecto,
        resultadoProyecto,
        totalCostesDirectos,
        totalCostesIndirectosEstudio,
        totalPresupuesto,
      },
    } = yield eCobraApi.deleteProjectPresuppositionIndirectCost(payload);
    yield put(Actions.projectDetail.deleteIndirectCostSuccess(payload));
    yield put(
      Actions.projectDetail.updateProjectGlobals({
        costeTotalProyecto,
        resultadoProyecto,
        totalCostesDirectos,
        totalCostesIndirectosEstudio,
        totalPresupuesto,
      })
    );
  } catch (error) {
    yield put(Actions.projectDetail.deleteIndirectCostError(error));
  }
}

function* watchGetProductions() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_PRODUCTIONS, getProductionsStart);
}
function* getProductionsStart({ payload }) {
  try {
    const { data } = yield eCobraApi.getProjectProductions(payload);
    yield put(Actions.projectDetail.getProjectProductionsSuccess(data));
  } catch (error) {
    yield put(Actions.projectDetail.getProjectProductionsError(error));
  }
}

function* watchSetProduction() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SET_PRODUCTION, setProductionStart);
}
function* setProductionStart({ payload: { productionData, callback } }) {
  try {
    const { data } = yield eCobraApi.setProjectProduction(productionData);
    yield put(Actions.projectDetail.setProjectProductionSuccess(data));
  } catch (error) {
    yield put(Actions.projectDetail.setProjectProductionError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchCloseProductionMonth() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(CLOSE_PRODUCTION_MONTH, closeProductionMonthStart);
}
function* closeProductionMonthStart({ payload: { id, callback } }) {
  try {
    yield eCobraApi.closeProjectProductionMonth(id);
    yield put(Actions.projectDetail.closeProductionMonthSuccess());
  } catch (error) {
    yield put(Actions.projectDetail.closeProductionMonthError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchGetProjectTimelines() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_PROJECT_TIMELINES, getProjectTimelinesStart);
}
function* getProjectTimelinesStart({ payload }) {
  try {
    const { data } = yield eCobraApi.getProjectTimeline(payload);
    yield put(Actions.projectDetail.getProjectTimelineSuccess(data));
  } catch (error) {
    yield put(Actions.projectDetail.getProjectTimelineError(error));
  }
}

function* watchGetProjectIndirectCosts() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_INDIRECT_COSTS, getProjectIndirectCostsStart);
}
function* getProjectIndirectCostsStart({
  payload: { idProject, idTimeline, mode },
}) {
  try {
    let apiMethod;
    switch (mode) {
      case 'ALL':
        apiMethod = eCobraApi.getProjectIndirectCostsAll;
        break;
      case 'SPECIFIC':
        apiMethod = eCobraApi.getProjectIndirectCostsAtMonth;
        break;
      case 'CURRENT':
      default:
        apiMethod = eCobraApi.getProjectIndirectCostsCurrentMonth;
        break;
    }
    const { data } = yield apiMethod({ idProject, idTimeline });
    yield put(Actions.projectDetail.getProjectIndirectCostsSuccess(data));
  } catch (error) {
    yield put(Actions.projectDetail.getProjectIndirectCostsError(error));
  }
}

function* watchSetProjectIndirectCost() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(ADD_INDIRECT_COST, setProjectIndirectCostStart);
}
function* setProjectIndirectCostStart({ payload: { costData, callback } }) {
  try {
    const { data } = yield eCobraApi.setProjectIndirectCost(costData);
    yield put(Actions.projectDetail.addProjectIndirectCostSuccess(data));
  } catch (error) {
    yield put(Actions.projectDetail.addProjectIndirectCostError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchUpdateProjectIndirectCost() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_INDIRECT_COST, updateProjectIndirectCostStart);
}
function* updateProjectIndirectCostStart({
  payload: { costData, callback, isAllMode },
}) {
  try {
    const { data } = yield eCobraApi.updateProjectIndirectCost(costData);
    yield put(
      Actions.projectDetail.updateProjectIndirectCostSuccess(data, isAllMode)
    );
  } catch (error) {
    yield put(Actions.projectDetail.updateProjectIndirectCostError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchDelProjectIndirectCost() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DEL_INDIRECT_COST, delProjectIndirectCostStart);
}
function* delProjectIndirectCostStart({
  payload: { idProject, idCost, isAllMode },
}) {
  try {
    const { data } = yield eCobraApi.deleteProjectIndirectCost({
      idProject,
      idCost,
    });
    yield put(
      Actions.projectDetail.delProjectIndirectCostSuccess(
        idCost,
        data,
        isAllMode
      )
    );
  } catch (error) {
    yield put(Actions.projectDetail.delProjectIndirectCostError(error));
  }
}

function* watchGetProjectDeliveryNotes() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_DELIVERYNOTES, getProjectDeliveryNotesStart);
}
function* getProjectDeliveryNotesStart({
  payload: { idProject, idTimeline, mode },
}) {
  try {
    let apiMethod;
    switch (mode) {
      case 'ALL':
        apiMethod = eCobraApi.getProjectDeliveryNotesAll;
        break;
      case 'SPECIFIC':
        apiMethod = eCobraApi.getProjectDeliveryNotesAtMonth;
        break;
      case 'CURRENT':
      default:
        apiMethod = eCobraApi.getProjectDeliveryNotesCurrentMonth;
        break;
    }
    const { data } = yield apiMethod({ idProject, idTimeline });
    yield put(Actions.projectDetail.getProjectDeliveryNotesSuccess(data));
  } catch (error) {
    yield put(Actions.projectDetail.getProjectDeliveryNotesError(error));
  }
}

function* watchSetProjectDeliveryNote() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(ADD_DELIVERYNOTE, setProjectDeliveryNoteStart);
}
function* setProjectDeliveryNoteStart({ payload: { deliveryData, callback } }) {
  try {
    const { data } = yield eCobraApi.setProjectDeliveryNote(deliveryData);
    yield put(Actions.projectDetail.addProjectDeliveryNoteSuccess(data));
  } catch (error) {
    yield put(Actions.projectDetail.addProjectDeliveryNoteError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchUpdateProjectDeliveryNote() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_DELIVERYNOTE, updateProjectDeliveryNoteStart);
}
function* updateProjectDeliveryNoteStart({
  payload: { deliveryData, callback, isAllMode },
}) {
  try {
    const { data } = yield eCobraApi.updateProjectDeliveryNote(deliveryData);
    yield put(
      Actions.projectDetail.updateProjectDeliveryNoteSuccess(data, isAllMode)
    );
  } catch (error) {
    yield put(Actions.projectDetail.updateProjectDeliveryNoteError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchDelProjectDeliveryNote() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DEL_DELIVERYNOTE, delProjectDeliveryNoteStart);
}
function* delProjectDeliveryNoteStart({
  payload: { idProject, idDelivery, isAllMode },
}) {
  try {
    const { data } = yield eCobraApi.deleteProjectDeliveryNote({
      idProject,
      idDelivery,
    });
    yield put(
      Actions.projectDetail.delProjectDeliveryNoteSuccess(
        idDelivery,
        data,
        isAllMode
      )
    );
  } catch (error) {
    yield put(Actions.projectDetail.delProjectDeliveryNoteError(error));
  }
}

function* watchGetProjectSubcontractors() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_SUBCONTRACTORS, getProjectSubcontractorsStart);
}
function* getProjectSubcontractorsStart({
  payload: { idProject, idTimeline, mode },
}) {
  try {
    let apiMethod;
    switch (mode) {
      case 'ALL':
        apiMethod = eCobraApi.getProjectSubcontractorsAll;
        break;
      case 'SPECIFIC':
        apiMethod = eCobraApi.getProjectSubcontractorsAtMonth;
        break;
      case 'CURRENT':
      default:
        apiMethod = eCobraApi.getProjectSubcontractorsCurrentMonth;
        break;
    }
    const { data } = yield apiMethod({ idProject, idTimeline });
    yield put(Actions.projectDetail.getProjectSubcontractorsSuccess(data));
  } catch (error) {
    yield put(Actions.projectDetail.getProjectSubcontractorsError(error));
  }
}

function* watchSetProjectSubcontractor() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(ADD_SUBCONTRACTOR, setProjectSubcontractorStart);
}
function* setProjectSubcontractorStart({
  payload: { subcontractorData, callback },
}) {
  try {
    const { data } = yield eCobraApi.setProjectSubcontractor(subcontractorData);
    yield put(Actions.projectDetail.addProjectSubcontractorSuccess(data));
  } catch (error) {
    yield put(Actions.projectDetail.addProjectSubcontractorError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchUpdateProjectSubcontractor() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_SUBCONTRACTOR, updateProjectSubcontractorStart);
}
function* updateProjectSubcontractorStart({
  payload: { subcontractorData, callback, isAllMode },
}) {
  try {
    const { data } = yield eCobraApi.updateProjectSubcontractor(
      subcontractorData
    );
    yield put(
      Actions.projectDetail.updateProjectSubcontractorSuccess(data, isAllMode)
    );
  } catch (error) {
    yield put(Actions.projectDetail.updateProjectDeliveryNoteError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchDelProjectSubcontractor() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DEL_SUBCONTRACTOR, delProjectSubcontractorStart);
}
function* delProjectSubcontractorStart({
  payload: { idProject, idSubcontractor, isAllMode },
}) {
  try {
    const { data } = yield eCobraApi.deleteProjectSubcontractor({
      idProject,
      idSubcontractor,
    });
    yield put(
      Actions.projectDetail.delProjectSubcontractorSuccess(
        idSubcontractor,
        data,
        isAllMode
      )
    );
  } catch (error) {
    yield put(Actions.projectDetail.delProjectSubcontractorError(error));
  }
}

function* watchCloseInitialStudy() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(CLOSE_INITIAL_STUDY, closeInitialStudyStart);
}
function* closeInitialStudyStart({ payload: { id, callback } }) {
  try {
    yield eCobraApi.closeInitialStudy(id);
    yield put(Actions.projectDetail.closeInitialStudySuccess());
  } catch (error) {
    yield put(Actions.projectDetail.closeInitialStudyError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchApproveInitialStudy() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(APPROVE_INITIAL_STUDY, approveInitialStudyStart);
}
function* approveInitialStudyStart({ payload: { id, callback } }) {
  try {
    yield eCobraApi.approveInitialStudy(id);
    yield put(Actions.projectDetail.approveInitialStudySuccess());
  } catch (error) {
    yield put(Actions.projectDetail.approveInitialStudyError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchReviseInitialStudy() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REVISE_INITIAL_STUDY, reviseInitialStudyStart);
}
function* reviseInitialStudyStart({ payload: { id, callback } }) {
  try {
    yield eCobraApi.reviseInitialStudy(id);
    yield put(Actions.projectDetail.reviseInitialStudySuccess());
  } catch (error) {
    yield put(Actions.projectDetail.reviseInitialStudyError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchCloseRevisedInitialStudy() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(CLOSE_REVISED_INITIAL_STUDY, closeRevisedInitialStudyStart);
}
function* closeRevisedInitialStudyStart({ payload: { id, callback } }) {
  try {
    yield eCobraApi.closeRevisedInitialStudy(id);
    yield put(Actions.projectDetail.closeRevisedInitialStudySuccess());
  } catch (error) {
    yield put(Actions.projectDetail.closeRevisedInitialStudyError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchStartConstruction() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(START_CONSTRUCTION, startConstructionStart);
}
function* startConstructionStart({ payload: { id, callback } }) {
  try {
    yield eCobraApi.startConstruction(id);
    yield put(Actions.projectDetail.startConstructionSuccess());
  } catch (error) {
    yield put(Actions.projectDetail.startConstructionError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchCancelConstruction() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(CANCEL_CONSTRUCTION, cancelConstructionStart);
}
function* cancelConstructionStart({ payload: { id, callback } }) {
  try {
    yield eCobraApi.cancelConstruction(id);
    yield put(Actions.projectDetail.cancelConstructionSuccess());
  } catch (error) {
    yield put(Actions.projectDetail.cancelConstructionError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

function* watchFinishConstruction() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FINISH_CONSTRUCTION, finishConstructionStart);
}
function* finishConstructionStart({ payload: { id, callback } }) {
  try {
    yield eCobraApi.finishConstruction(id);
    yield put(Actions.projectDetail.finishConstructionSuccess());
  } catch (error) {
    yield put(Actions.projectDetail.finishConstructionError(error));
  } finally {
    // eslint-disable-next-line no-unused-expressions
    !!callback && callback();
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetProjectDetail),
    fork(watchDeleteProjectDetail),
    fork(watchAddProjectPresuposition),
    fork(watchSetDirectCost),
    fork(watchUpdateDirectCost),
    fork(watchSetSubDirectCost),
    fork(watchUpdateSubDirectCost),
    fork(watchDeleteSubDirectCost),
    fork(watchSetIndirectCost),
    fork(watchUpdateIndirectCost),
    fork(watchDeleteIndirectCost),
    fork(watchGetProductions),
    fork(watchSetProduction),
    fork(watchCloseProductionMonth),
    fork(watchGetProjectTimelines),
    fork(watchGetProjectIndirectCosts),
    fork(watchSetProjectIndirectCost),
    fork(watchUpdateProjectIndirectCost),
    fork(watchDelProjectIndirectCost),
    fork(watchGetProjectDeliveryNotes),
    fork(watchSetProjectDeliveryNote),
    fork(watchUpdateProjectDeliveryNote),
    fork(watchDelProjectDeliveryNote),
    fork(watchGetProjectSubcontractors),
    fork(watchSetProjectSubcontractor),
    fork(watchUpdateProjectSubcontractor),
    fork(watchDelProjectSubcontractor),
    fork(watchCloseInitialStudy),
    fork(watchApproveInitialStudy),
    fork(watchReviseInitialStudy),
    fork(watchCloseRevisedInitialStudy),
    fork(watchStartConstruction),
    fork(watchCancelConstruction),
    fork(watchFinishConstruction),
    fork(watchAddProjectExtension),
  ]);
}
