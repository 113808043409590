export default {
  home: '/',
  auth: {
    base: '/auth',
    login: '/auth/login',
    register: '/auth/register',
    forgotPassword: '/auth/forgot-password',
    resetPassword: '/auth/reset-password',
  },
  app: {
    base: '/app',
    projects: {
      base: '/app/projects',
      list: '/app/projects/list',
      detail: '/app/projects/detail/:id',
    },
  },
  error: '/error',
  unauthorized: '/unauthorized',
};
