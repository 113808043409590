export const UserRole = {
  SU: 0,
  JefeObra: 1,
  Gerente: 2,
  Administrativo: 3,
};

export const ProjectStatus = {
  PendienteDePresupuesto: 0,
  EstudioInicial: 1,
  PendienteAprobacion: 2,
  Aprobado: 3,
  EnRevision: 4,
  EnEjecucion: 5,
  Finalizado: 6,
  Revisado: 7,
  Cancelado: 8,
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'es-ES';
export const localeOptions = [{ id: 'es-ES' }, { id: 'es-PE' }];

export const adminRoot = '/app';
export const searchPath = `${adminRoot}/#`;

export const themeColorStorageKey = '__theme_selected_color';
export const defaultColor = 'light.bluenavy';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
