import { SET_ACTION_CONFIRM, CLEAR_ACTION_CONFIRM } from '../actions';

const INIT_STATE = {
  title: '',
  content: [],
  ctaText: '',
  callToAction: null,
  cancelCallback: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ACTION_CONFIRM:
      return {
        ...action.payload,
      };
    case CLEAR_ACTION_CONFIRM:
      return {
        ...INIT_STATE,
      };
    default:
      return { ...state };
  }
};
